@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
    height: 100vh;
}
h1.conversationHeader {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 64pt;
}

h1.public-card-header {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 48px;
    color: #000000;
}
h1.private-card-header {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 48px;
    color: #444444;
}

.ant-card.conversation-summary {
    margin-bottom: 40px;
    font-family: 'Roboto';
    font-weight: 900;
    word-wrap: 'break-word';
}
.public-card.ant-card {
    background-color: #ffffff;
    color: #000000;
}
.private-card.ant-card {
    background-color: #e2e2e2;
    color: #343434;
}
.featured-body {
    text-align: left;
    word-wrap: break-word;
}
.status-badge {
    float: right;
    font-size: 35px;
}

.ant-card-wider-padding .ant-card-body {
  padding: 17px 32px;
}

.system_message {
  background-color: #eeeeee;
  padding: 6pt;
  color: rgba(193, 193, 193, 0.85);
}

.branch_message {
  border-right-style: inset;
  border-right-color: rgba(238, 68, 35);
  border-right-width: 4px;
}

.embedded_message {
  background-color: #ffeeee;
}
.highlight {
  position: "relative";
  font-size: 16px;
  opacity: 0.2;
}

.highlightPadding {
  padding-right: 14px;
  font-size: 16px;
  opacity: 0.2;
}

.highlightPadding,
.highlight:hover {
  opacity: 1;
}

.embedded_message span.username {
  color: #939393;
}

span.username {
  font-family: "Roboto-Medium";
  font-size: 24px;
  color: "rgba(68, 68, 68, 0.85)";
  margin-left: 16px;
}

@font-face {
  font-family: "AlfaSlabOne-Regular";
  src: local("AlfaSlabOne-Regular"),
    url(/static/media/AlfaSlabOne-Regular.0450a101.ttf) format("ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"), url(/static/media/Roboto-Medium.d0884059.ttf) format("ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"), url(/static/media/Roboto-Bold.ee7b96fa.ttf) format("ttf");
}

@font-face {
  font-family: "Charter-Regular";
  src: local("Charter-Regular"), url(/static/media/Charter-Regular.88a88e90.otf) format("otf");
}

.incognito.subnav {
  background-color: #4d4d4d;
}

.public.subnav {
  background-color: #ffffff;
}
.galley-header.ant-layout-header > ul > li {
  z-index: 900;
}

.mobileBanner {
  display: none;
  width: 100%;
}

.compose-button {
  padding: "0px 40px";
  border-style: "solid";
  border-width: 1.5px;
  padding: "0px 40px";
  border-radius: 20px;
  font-size: 16px;
  height: 35px;
}

.messageImageMetadataContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: "0px 32px 0px 0px";
}

.systemMessageText {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
}

.conversation-title-sm {
  display: none;
}
.display-name {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #9b9b9b;
}
.display-time {
  font-size: 12px;
  font-weight: 400;
  color: #9b9b9b;
}
.display-name-mobile {
  display: none;
}
.public.mobile-subnav {
  display: none !important;
}
.incognito.mobile-subnav {
  display: none !important;
}
.highlight-message {
  vertical-align: "sub";
  margin-left: 10;
}
h1.header-logo-mobile {
  display: none !important;
}

.ant-list-split .ant-list-item {
  border: none !important;
}

/* Large Macbook Pro devices (large desktops, 1200px and up) */
/* ------ Header and Subnav ------------------------- */
/* Galley Logo */
h1.public.header-logo,
h1.header-logo.incognito {
  font-size: 21px !important;
}

h1.public.subnav {
  font-size: 20px !important;
}

/* subnav height */
.galley-header-drop {
  height: 45px !important;
}

.ant-menu-item.icon-menu.secondaryItem {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* Compose Or Login Button  */
.compose-button {
  font-size: 14px;
  height: 31px !important;
  border-width: 1px !important;
}

.ant-menu-horizontal {
  line-height: 43px !important;
}

/* Login Button */
button#loginTopNav {
  font-size: 12px;
}

.ant-layout-header {
  height: 56px !important;
}

/* ------ Header and Subnav ------------------------- */

/* Converstation title */
h1.public-card-header {
  font-size: 42px !important;
}

/* Message Body Text */
p.message-body,
a.linkified {
  font-size: 18.5px !important;
}

.messageImageMetadataContainer
  > div
  > span.ant-avatar.ant-avatar-circle.ant-avatar-image {
  width: 40px !important;
  height: 40px !important;
}

.messageImageMetadataContainer > div > div > p {
  font-size: 15px !important;
}

.messageImageMetadataContainer > div > div > p:nth-child(2) {
  font-size: 11px !important;
}

/* Nav items: featured - recent - private */
li.ant-menu-item.red-underline,
li.ant-menu-item.white-underline {
  font-size: 12px;
}

/* ant-card featured screen ----------------*/

.ant-card-body {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  /* padding: 0f; */
}

.status-badge {
  font-size: 33px !important;
}

span.display-time {
  font-size: 11px !important;
}

/* WelcomeMat Icons */
.welcomeMat
  > .ant-row
  > div
  > div
  > div
  > span.ant-avatar.ant-avatar-circle.ant-avatar-image {
  width: 52px !important;
  height: 52px !important;
}

.welcomeMat > .ant-row > div > div > div > span.ant-avatar.ant-avatar-circle {
  width: 52px !important;
  height: 52px !important;
}

.welcomeMat > .ant-row > div > div > div {
  max-width: 65px !important;
}

.welcomeMat > .ant-row > div > div > div > p {
  font-size: 12px !important;
}

/* Bell Icon in Nav */
.ant-dropdown-trigger.ant-badge > div {
  height: 30px !important;
  width: 30px !important;
  border-width: 1px !important;
}

/* featured card avatar */
.featured-body
  > div
  > div
  > span.ant-avatar.ant-avatar-circle.ant-avatar-image {
  height: 40px !important;
  width: 40px !important;
}

.ant-dropdown-trigger > span.ant-avatar.ant-avatar-circle.ant-avatar-image {
  height: 30px !important;
  width: 30px !important;
}

/* System Message ----------------*/
.systemMessageText {
  font-size: 14px !important;
}

.system-message-container {
  padding: 1px !important;
}

.anticon.anticon-camera {
  font-size: 21px;
}

p.sub-detail {
  font-size: 14px !important;
  font-family: "Roboto";
  padding-bottom: 10px;
}

p.detail {
  font-size: 20px !important;
  margin-bottom: 0;
  padding-bottom: 10px;
}

/* system message emoji */
.system-emoji {
  font-size: 12px !important;
}

.conversation-detail-container div > div > div > span.ant-avatar {
  width: 38px !important;
  height: 38px !important;
}

.ant-row.creator-container {
  overflow-wrap: break-word !important;
  height: 57px !important;
  border-bottom-style: "solid" !important;
}

.conversation-detail-container div > div > .ant-row {
  /* padding-top: 5px !important;
    padding-bottom: 5px !important; */
}

.detail-subtext div > div > div > {
  font-size: 12px !important;
}

.detail-block {
  font-size: 20px !important;
}

p.detail-block {
  margin: 0;
}

.status-emoji-block {
  display: -webkit-flex;
  display: flex;
  margin: 0;
  font-size: 20px !important;
}

.status-emoji {
  display: -webkit-flex;
  display: flex;
  font-size: 20px !important;
  padding-bottom: 10px;
}

/* Message Screen ------------------------ */
/* public/conversations/-M7wfc2F7MIkzlGXXn8B/message/-M7x8y9r689BU8NFH7fm */
.ant-row-flex > div > p > span {
  font-size: 30px !important;
}

.ant-row-flex > div > p:nth-child(2) > span {
  font-size: 12px !important;
}

/* MOBILE WEB STYLES */

@media only screen and (max-width: 1080px) {
  .convo-avatar {
    width: 35px !important;
    height: 35px !important;
  }

  .message-input-avatar {
    width: 35px !important;
    height: 35px !important;
  }
}
@media only screen and (max-width: 812px) {
  /* .welcomeMat
    > .ant-row
    > div
    > div
    > div
    > span.ant-avatar.ant-avatar-circle.ant-avatar-image,
  .ant-avatar.ant-avatar-circle {
    width: 42px !important;
    height: 42px !important;
  } */

  p.desktop-participate {
    display: none;
  }
  h1.header-logo {
    display: none;
  }

  .convo-avatar {
    width: 35px !important;
    height: 35px !important;
  }

  h1.header-logo-mobile {
    display: block !important;
    text-align: center;
    display: block;
    font-family: "Roboto";
    font-weight: 900;
    font-size: 18pt;
    color: #c0223b;
    margin: inherit;
    cursor: pointer;
  }
  .ant-card-body {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .galley-header-drop-padder {
    margin-left: 10px;
  }
  .highlight-message {
    vertical-align: "sub";
    margin-left: 0;
    font-size: 12px;
  }
  .public.subnav {
    display: none !important;
  }
  .incognito.subnav {
    display: none !important;
  }
  .public.mobile-subnav {
    display: block;
  }
  h1.public.mobile-subnav {
    font-size: 12pt !important;
    margin-left: 10px;
  }
  .ant-layout-header {
    height: 100px;
  }
  .display-name {
    display: none;
  }
  .display-time {
    display: none;
  }
  .display-name-mobile {
    display: block;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    color: #9b9b9b;
  }
  .conversation-items {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .status-badge {
    font-size: 20px;
  }

  .featured-body {
    line-height: 1;
  }
  span.display-name {
    font-size: 14px !important;
  }
  .conversation-title {
    display: none !important;
  }
  .conversation-title-sm {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
  }
  /* .galley-header {
    margin-bottom: -40px !important;
  } */
  .highlight {
    display: none;
  }
  .trusted-like-avatar {
    display: none;
  }

  h1.header-logo.public {
    width: "100%";
  }
  h1.public-card-header {
    font-size: 22px;
  }

  /* .ant-card-body {
    padding-right: 5px;
    padding-left: 5px !important;
  } */
  .systemMessageText {
    -webkit-align-self: center;
            align-self: center;
    font-size: 12px;
  }

  p.message-body {
    font-size: 18px !important;
  }
  a.linkified {
    font-size: 18px !important;
  }

  .galley-header-drop-padder {
    min-width: 0px !important;
  }

  li.ant-menu-item {
    /* text-align: center; */
    display: none;
  }
  li.ant-menu-item.gLogo {
    width: 50%;
  }
  .compose-button {
    margin-right: 100px;
  }
  li.ant-menu-item.placeholder.icon-menu {
    padding-left: 0px;
  }
  li.secondaryItem {
    width: 33% !important;
  }

  .mobileBanner {
    display: block;
    position: fixed;
    bottom: 0px;
  }
  div.galley-header-drop {
    height: 100% !important;
  }
  .galley-header-drop-padder {
    padding-right: 0;
  }
  p.message-body {
    line-height: 1.4;
  }
  span.display-name {
    font-size: 17px;
  }
  .trustSwitch {
    padding-top: 100px;
  }

  .trustText {
    font-size: 10px;
  }

  #navCompose {
    width: "60%";
  }
}

/* MOBILE WEB STYLES */

a.linkified {
  color: #c0223b;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 21px;
}

p.message-body {
  margin-bottom: 0;
  overflow-wrap: break-word;
}

a:hover {
  color: #c0223b;
}

::-webkit-input-placeholder {
  font-size: 14pt;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14pt;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14pt;
}

:-ms-input-placeholder {
  font-size: 14pt;
}

textarea.ant-input:focus {
  box-shadow: none;
  border: none;
}

.public-DraftEditorPlaceholder-inner {
  padding: 0px !important;
}

body {
  height: 100%;
  background-color: #f0f2f5;
}
hr {
  background-color: #e9e9e9;
  height: 1px;
  border: 0;
}
.ant-badge-count {
  background: #c0223b;
}
.ant-btn-primary {
  background-color: #c0223b;
  border-color: #c0223b;
}

.ant-btn-primary:hover {
  background-color: #c0223b;
  border-color: #c0223b;
}

.ant-btn-primary:focus {
  background-color: #c0223b;
  border-color: #c0223b;
}

.ant-btn-primary:active {
  background-color: #c0223b;
  border-color: #c0223b;
}

.ant-menu.ant-menu-light {
  z-index: 10;
  color: transparent;
}
.ant-switch-checked {
  background-color: #c0223b;
}
.red-underline.ant-menu-item:hover {
  border-bottom: 2px solid #c0223b !important;
  color: inherit !important;
}
.white-underline.ant-menu-item:hover {
  border-bottom: 2px solid white !important;
  color: inherit !important;
}
.text-menu.ant-menu-item:hover {
  border-bottom: 2px solid #c0223b !important;
  color: inherit !important;
}
.icon-menu.ant-menu-item:hover {
  border-bottom: none !important;
  color: inherit !important;
}
.ant-menu-item-active {
  border-bottom: none !important;
  color: inherit !important;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: none;
  color: none;
}
.red-underline.ant-menu-item-selected.galley {
  border-bottom: 2px solid #c0223b;
  color: #c0223b;
}
.white-underline.ant-menu-item-selected.galley {
  border-bottom: 2px solid white;
  color: white;
}

.galley-header-drop {
  box-shadow: 0 2px 2px 0 rgba(39, 36, 36, 0.1),
    0 6px 20px 0 rgba(36, 3, 3, 0.1);
}

.galley-header-drop-padder {
  min-width: 350px;
  padding-right: 20;
}

h1.header-logo {
  text-align: center;
  font-family: "Roboto";
  font-weight: 900;
  font-size: 18pt;
  color: #c0223b;
  margin: inherit;
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.ant-menu-horizontal > .ant-menu-item > a.incognito {
  color: white;
}

.ant-menu-horizontal > .ant-menu-item > a.public {
  color: rgba(0, 0, 0, 0.65);
}

ul.ant-menu.incognito {
  background-color: #4d4d4d;
}

.ant-dropdown-menu-item:hover {
  background-color: inherit;
}

.pointer:hover {
  cursor: "pointer";
}

.header-logo.incognito {
  color: #bcbcbc;
}

.ant-card-head-title {
  font-family: "Roboto";
  font-weight: 900;
}

li.ant-menu-item {
  font-family: "Roboto" !important;
  font-weight: 400;
}

.ant-card,
.ant-card-bordered {
  font-family: "Roboto";
  font-weight: 400;
}
.display-name {
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: rgba(68, 68, 68, 0.85);
}

h1.public-card-header {
  cursor: pointer;
}

h1.private-card-header {
  cursor: pointer;
}

a.public.active {
  color: #c0223b !important;
}

.anticon-key {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH";
}

.header-logo.incognito.subnav {
  color: white;
}

.header-logo.public {
  color: #c0223b;
}

.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-menu-item:hover {
  border-bottom: none;
  color: inherit;
}

.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  padding: 0px 11px;
}

.ant-card-head-title {
  font-size: 19px;
}

